import { saveAs } from 'file-saver';
import { bwicService } from '../services';
import { uploadDocumentsActions } from '.';

export const documentsActions = {
    init,
    reset,
    downloadSecurityDocs
}

function init() {
    return (dispatch, getState) => {
        const { grid } = getState();
        
        const securityIdList = grid.dataItems
            .filter(s => !s.draft)
            .map(s => s.securityId);

            dispatch(uploadDocumentsActions.init(securityIdList));
    }
}

function reset() {
    return dispatch => {
        dispatch(uploadDocumentsActions.reset());
    };
}

function downloadSecurityDocs(isinCusip) {
    return () => {
        bwicService
            .getSecurityDocsZip(isinCusip)
            .then(file => saveAs(file.blob, file.name));
    }
}
